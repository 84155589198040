const GATSBY_DONATION_API = process.env.GATSBY_DONATION_API
const GATSBY_FIREBASE_API_KEY = process.env.GATSBY_FIREBASE_API_KEY
const GATSBY_FIREBASE_AUTH_DOMAIN = process.env.GATSBY_FIREBASE_AUTH_DOMAIN
const GATSBY_SENTRY_DSN = process.env.GATSBY_SENTRY_DSN
const GATSBY_STRIPE_PUB_KEY = process.env.GATSBY_STRIPE_PUB_KEY
const VERCEL_GITHUB_COMMIT_REF = process.env.VERCEL_GITHUB_COMMIT_REF
const GATSBY_GOOGLE_RECAPTCHA_KEY = process.env.GATSBY_GOOGLE_RECAPTCHA_KEY

if (!GATSBY_DONATION_API) {
  throw new Error('GATSBY_DONATION_API env variable is required')
}
if (!GATSBY_FIREBASE_API_KEY) {
  throw new Error('GATSBY_FIREBASE_API_KEY env variable is required')
}
if (!GATSBY_FIREBASE_AUTH_DOMAIN) {
  throw new Error('GATSBY_FIREBASE_AUTH_DOMAIN env variable is required')
}
if (!GATSBY_STRIPE_PUB_KEY) {
  throw new Error('GATSBY_STRIPE_PUB_KEY env variable is required')
}
if (!GATSBY_GOOGLE_RECAPTCHA_KEY) {
  throw new Error('GATSBY_GOOGLE_RECAPTCHA_KEY env variable is required')
}

export default {
  GATSBY_DONATION_API,
  GATSBY_FIREBASE_API_KEY,
  GATSBY_FIREBASE_AUTH_DOMAIN,
  GATSBY_SENTRY_DSN,
  GATSBY_STRIPE_PUB_KEY,
  VERCEL_GITHUB_COMMIT_REF,
  GATSBY_GOOGLE_RECAPTCHA_KEY,
}
