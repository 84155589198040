import SEO from 'components/SEO';
import CauseHero from 'components/CauseHero';
import { graphql } from 'gatsby';
import { Box } from 'components/Box';
import { Text } from 'components/Text';
import BackgroundImage from 'gatsby-background-image';
import { Container } from 'components/Container';
import { ContainerImageWide } from 'components/ContainerImageWide';
import { CallToAction } from 'components/CallToAction';
import * as React from 'react';
export default {
  SEO,
  CauseHero,
  graphql,
  Box,
  Text,
  BackgroundImage,
  Container,
  ContainerImageWide,
  CallToAction,
  React
};