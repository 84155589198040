import React from 'react'
import {Helmet} from 'react-helmet'
import {useStaticQuery, graphql} from 'gatsby'
import {useMultilingualLinks} from 'hooks/useOtherLanguageLink'
import {SeoQuery} from '../graphqlTypes'

interface ISEOProps {
  description: string
  lang?: string
  title: string
  meta?: Array<{
    name: string
    content: string
  }>
}
const SEO: React.FC<ISEOProps> = ({
  description,
  lang = 'en',
  meta = [],
  title,
}) => {
  const {site} = useStaticQuery<SeoQuery>(
    graphql`
      query SEO {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `,
  )

  const metaDescription = description || site.siteMetadata.description
  const displayedTitle = title
    ? `${title} | ${site.siteMetadata.title}`
    : site.siteMetadata.title
  const siteUrl = site.siteMetadata.siteUrl
  const multilingualLinks = useMultilingualLinks()

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={displayedTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      {Object.values(multilingualLinks).map(({language, path}) => {
        return (
          <link
            key={language}
            rel="alternate"
            href={siteUrl + path}
            hrefLang={language}
          />
        )
      })}
      <meta name="p:domain_verify" content="9f2dac1fe7f7ea9fce27466fb9755916" />
    </Helmet>
  )
}

export default SEO
