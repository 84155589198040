/** @jsx jsx */
import BackgroundImage from 'gatsby-background-image'
import {jsx} from 'theme-ui'
import {graphql} from 'gatsby'
import {WaterForAllBuildYourOwnWellFragment} from '../graphqlTypes'
import ulCircle from 'images/ul-circle.png'
import DonateNowButton from './DonateNowButton'

type Props = {
  data: WaterForAllBuildYourOwnWellFragment
  title: string
  subtitle: string
  content: string
  buttonText: string
}

const CauseWater4AllBuildYourOwnWell: React.FC<Props> = ({
  data,
  title,
  subtitle,
  content,
  buttonText,
}) => {
  return (
    <BackgroundImage
      id="water4all__build_your_own_well"
      Tag="section"
      style={{
        backgroundSize: '',
        backgroundPosition: '',
      }}
      sx={{
        py: 37,
        px: [15, , 40],
        width: '100%',
        height: [1120, 1250, 661],
        display: 'flex',
        '&::before, &::after': {
          backgroundPositionX: ['90%', , 'center'],
          backgroundPositionY: ['bottom', , 'center'],
          backgroundSize: ['180%', , 'unset'],
        },
      }}
      fluid={data.footerImage.childImageSharp.fluid}
      backgroundColor="#F4F7F8"
    >
      <div
        sx={{
          flex: 1,
          maxWidth: ['100%', , 1440 - 206 * 2],
          mx: 'auto',
          display: 'flex',
          alignItems: ['flex-start', , 'center'],
        }}
      >
        <div
          sx={{
            maxWidth: ['100%', 432],
            px: [15, , 0],
          }}
        >
          <h2
            sx={{
              fontFamily: 'Lato Medium',
              fontWeight: 400,
              fontSize: 28,
              mt: 0,
              mb: 0,
            }}
          >
            {title}
          </h2>
          <div
            sx={{
              fontWeight: 300,
              fontSize: 24,
            }}
          >
            {subtitle}
          </div>
          <div
            sx={{
              ul: {
                pl: 17,
                listStyleImage: `url(${ulCircle})`,
                fontSize: 14,
                lineHeight: '22px',
                color: 'soft',
              },
              b: {
                color: 'black',
              },
              li: {
                mt: 22,
                pl: '1em',
              },
            }}
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          ></div>
          <DonateNowButton
            to="water-for-all"
            sx={{
              mt: 16,
            }}
          >
            {buttonText}
          </DonateNowButton>
        </div>
      </div>
    </BackgroundImage>
  )
}

export default CauseWater4AllBuildYourOwnWell

export const query = graphql`
  fragment WaterForAllBuildYourOwnWell on Query {
    footerImage: file(relativePath: {eq: "w4all-footer.png"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
