/** @jsx jsx */
import {jsx} from 'theme-ui'

type FoodAid = {
  title: string
  description: string
  price: string
  backgroudColor: string
  color: string
}

type Props = {
  content: FoodAid[]
}

export const FoodAidContainer: React.FC<Props> = ({content}) => {
  return (
    <div
      sx={{
        flex: 1,
        maxWidth: ['100%', '50%', '50%'],
        mx: 0,
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: ['column', 'column', 'column'],
        alignItems: ['flex-start', 'flex-start', 'flex-start'],
        justifyContent: ['center'],
      }}
    >
      {content.map((c, index) => {
        return (
          <div
            key={index}
            style={{
              backgroundColor: c.backgroudColor,
              color: c.color,
              width: '100%',
              padding: '0 10px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexWrap: 'nowrap',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div style={{width: '70%'}}>
                <h4>{c.title}</h4>
                {c.description !== '' ? (
                  <p style={{lineHeight: '24px'}}>{c.description}</p>
                ) : null}
              </div>
              <h3>{c.price}</h3>
            </div>
          </div>
        )
      })}
    </div>
  )
}
