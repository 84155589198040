/** @jsx jsx */
import {ReactNode} from 'react'
import {jsx} from 'theme-ui'

export type VideoProps = {
  width: string[]
  children: ReactNode
}

export const VideoContainer: React.FC<VideoProps> = ({
  width = ['100%', '100%', '50%'],

  children,
}) => {
  return (
    <div
      sx={{
        width,
      }}
    >
      {children}
    </div>
  )
}
