import { Box } from 'theme-ui';
import SEO from 'components/SEO';
import CauseHero from 'components/CauseHero';
import { graphql } from 'gatsby';
import CauseWater4AllFeatures from 'components/CauseWater4AllFeatures';
import CauseWater4AllBuildYourOwnWell from 'components/CauseWater4AllBuildYourOwnWell';
import featureWater from 'images/feat-white-water.svg';
import featureMan from 'images/feat-white-man.svg';
import * as React from 'react';
export default {
  Box,
  SEO,
  CauseHero,
  graphql,
  CauseWater4AllFeatures,
  CauseWater4AllBuildYourOwnWell,
  featureWater,
  featureMan,
  React
};