/** @jsx jsx */
import {jsx} from 'theme-ui'
import LinkOrButton, {LinkOrButtonProps} from './LinkOrButton'

type Props = LinkOrButtonProps & {
  inverse?: boolean
}

const Button: React.FC<Props> = ({inverse, ...props}) => {
  const style = inverse
    ? {
        backgroundColor: 'white',
        borderColor: 'white',
        color: 'primary',
        '&:hover': {
          backgroundColor: 'primary',
          color: 'white',
        },
      }
    : {
        backgroundColor: 'primary',
        borderColor: 'primary',
        color: '#fff',
        '&:hover': {
          backgroundColor: '#fff',
          color: 'primary',
        },
      }
  return (
    <LinkOrButton
      sx={{
        display: 'inline-block',
        cursor: 'pointer',
        borderRadius: 5,
        py: 16,
        px: 50,
        transition: 'color, background-color 0.3s linear',
        textDecoration: 'none',
        fontWeight: 600,
        fontSize: 14,
        ...style,
      }}
      {...props}
    />
  )
}

export default Button
