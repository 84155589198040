export const navLinks: Array<{
  name: string
  to: {
    en: string
    fr: string
  }
  isButton?: boolean
}> = [
    {
      name: 'About us',
      to: {
        en: '/about-us/',
        fr: '/fr/about-us/',
      },
    },
    {
      name: 'Causes',
      to: {
        en: '/causes/',
        fr: '/fr/actions/',
      },
    },
    {
      name: 'Navbar.Ansar',
      to: {
        en: '/ansar/',
        fr: '/fr/ansar/',
      },
    },
    {
      name: 'Navbar.Zakat',
      to: {
        en: '/zakat/',
        fr: '/fr/zakat/',
      },
    },
    // {
    //   name: 'Contact Us',
    //   to: '/contact-us/',
    // },
    {
      name: 'My account',
      to: {
        en: '/account/',
        fr: '/fr/account/',
      },
      // isButton: true,
    },
    {
      name: 'Donate',
      to: {
        en: '/donate/',
        fr: '/fr/don/',
      },
      isButton: true,
    },
  ]
