/** @jsx jsx */
import {ReactNode} from 'react'
import {jsx} from 'theme-ui'

export type BoxProps = {
  backgroundColor?: string
  shadow?: boolean
  children: ReactNode
  lineHeight?: string | string[]
  height?: number
}

export const Box: React.FC<BoxProps> = ({
  backgroundColor = 'white',
  height = 440,
  shadow = true,
  lineHeight = ['20px', '22px', '24px'],
  children,
}) => {
  return (
    <div
      sx={{
        width: ['100%', height],
        backgroundColor,
        px: [28, 40, 28],
        pt: 20,
        pb: 18,
        lineHeight,
        borderRadius: 16,
        ...(shadow && {boxShadow: '0px 12px 26px #F2F2F2'}),
      }}
    >
      {children}
    </div>
  )
}
