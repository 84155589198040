/** @jsx jsx */
import { ReactNode } from 'react'
import { jsx } from 'theme-ui'

export type TextProps = {
  mb: number
  width: string[]
  children: ReactNode
}

export const Text: React.FC<TextProps> = ({
  mb = 50,
  width = ['100%', '100%', 'auto'],
  children,
}) => {
  return (
    <div
      sx={{
        width,
        px: [28, 40],
        display: 'flex',
        lineHeight: '24px',
        flexDirection: 'column',
        alignItems: ['center'],
        mx: 'auto',
        mb,
      }}
    >
      {children}
    </div>
  )
}
