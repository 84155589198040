/** @jsx jsx */
import {jsx} from 'theme-ui'

type Props = {
  color?: string
  icon: string
  numbers: string
  text: string
  isSmall?: boolean
}

const Feature: React.FC<Props> = ({
  color = '#F0F5F7',
  icon,
  numbers,
  text,
  isSmall = false,
}) => {
  return (
    <div
      sx={{
        mt: [30, 30, 0],
        display: 'flex',
        alignItems: 'center',
        mx: 20,
      }}
    >
      <div
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          bg: color,
          width: isSmall ? 52 : 76,
          height: isSmall ? 52 : 76,
          borderRadius: 99999,
        }}
      >
        <img src={icon} alt="" />
      </div>
      <div
        sx={{
          display: 'flex',
          flexDirection: 'column',
          ml: 13,
        }}
      >
        <span
          sx={{
            fontSize: isSmall ? 18 : [28, 28, 36],
          }}
        >
          {numbers}
        </span>
        <span
          sx={{
            color: isSmall ? 'soft' : ['soft', 'soft', 'black'],
            fontSize: isSmall ? 14 : [16, 16, 18],
          }}
        >
          {text}
        </span>
      </div>
    </div>
  )
}

export default Feature
