/** @jsx jsx */
import BackgroundImage from 'gatsby-background-image'
import {ReactNode} from 'react'
import {jsx} from 'theme-ui'
import {ImageSharpFluid, Maybe} from '../graphqlTypes'

type Props = {
  className?: string
  fluid: Maybe<ImageSharpFluid>
  height: number[] | number
  zIndex: number
  children: ReactNode
}

export const ContainerImageWide: React.FC<Props> = ({
  className,
  height = [650, 850, 500],
  fluid,
  zIndex = 0,
  children,
}) => {
  return (
    <BackgroundImage
      className={className}
      id="home__hero"
      Tag="div"
      style={{
        backgroundSize: '',
        backgroundPosition: '',
        zIndex,
      }}
      sx={{
        py: 37,
        px: [20, , 40],
        mb: [20, , 50],
        width: '100%',
        height,
        display: 'flex',
        '&::before, &::after': {
          backgroundPositionX: 'left',
          backgroundPositionY: ['bottom', , 'center'],
          backgroundSize: ['180%', , 'unset'],
        },
      }}
      fluid={fluid}
      backgroundColor="#F4F7F8"
    >
      <div
        sx={{
          flex: 1,
          maxWidth: ['100%', , '100%', 1026],
          mx: 'auto',
          display: 'flex',
          flexDirection: [, 'column', 'row-reverse'],
          alignItems: ['flex-start', 'center'],
        }}
      >
        {children}
      </div>
    </BackgroundImage>
  )
}
