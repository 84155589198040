/** @jsx jsx */
import {jsx} from 'theme-ui'
import DonateNowButton from './DonateNowButton'
import ShareButton from './ShareButton'
import {Box} from './Box'

export type CallToActionProps = {
  title: string
  text: string
  buttonText: string
  purpose: string
  pagePath: string
}

export const CallToAction: React.FC<CallToActionProps> = ({
  title,
  text,
  buttonText,
  purpose,
  pagePath,
}) => {
  return (
    <Box>
      <h1
        sx={{
          fontFamily: 'Lato Medium',
          fontSize: 22,
          my: 0,
        }}
      >
        {title}
      </h1>
      <p
        sx={{
          mt: 10,
          fontSize: [20, , 24],
          fontWeight: 300,
          mb: 0,
          lineHeight: '29px',
          color: '#042D37',
        }}
      >
        {text}
      </p>
      <div
        sx={{
          mt: 32,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
        }}
      >
        <DonateNowButton
          to={purpose}
          sx={{
            mt: 16,
          }}
        >
          {buttonText}
        </DonateNowButton>
        <ShareButton pagePath={pagePath}></ShareButton>
      </div>
    </Box>
  )
}
