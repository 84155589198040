import SEO from 'components/SEO';
import CauseHero from 'components/CauseHero';
import { graphql } from 'gatsby';
import { ContainerImageWide } from 'components/ContainerImageWide';
import { FoodAidContainer } from 'components/FoodAidContainer';
import { VideoContainer } from 'components/VideoContainer';
import { Embed } from 'theme-ui';
import * as React from 'react';
export default {
  SEO,
  CauseHero,
  graphql,
  ContainerImageWide,
  FoodAidContainer,
  VideoContainer,
  Embed,
  React
};