/** @jsx jsx */
import {jsx} from 'theme-ui'
import {ReactNode} from 'react'

type Props = {
  children: ReactNode[]
}

export const Container: React.FC<Props> = ({children: [column1, column2]}) => {
  return (
    <div
      sx={{
        flex: 1,
        maxWidth: ['100%', , '100%', 1026],
        mx: 'auto',
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: ['column', 'column', 'row'],
        alignItems: ['center', 'center', 'flex-start'],
        justifyContent: ['space-between'],
        pt: 20,
        pb: 20,
      }}
    >
      {column1}
      {column2}
    </div>
  )
}
