import React from 'react'
import useUser from 'hooks/useUser'
import {SWRConfig} from 'swr'
import ky from 'ky'

const SWRProvider: React.FC = ({children}) => {
  const {token} = useUser()
  return (
    <SWRConfig
      value={{
        refreshInterval: 1000 * 60 * 60 * 24,
        fetcher: async (
          input: RequestInfo,
          init?: RequestInit | undefined,
        ): Promise<Response> => {
          const newInit: RequestInit = {...init}
          if (token) {
            newInit.headers = {
              ...newInit.headers,
              Authorization: `Token ${token}`,
            }
          } else {
            console.warn(`Token is undefined, calling api without token`)
          }
          const res = await ky(input, newInit)
          return res.json()
        },
      }}
    >
      {children}
    </SWRConfig>
  )
}
export default SWRProvider
