import {useTranslation} from 'react-i18next'
import {useLocation} from '@reach/router'
import {Locale} from 'i18n'
import {navLinks} from 'data/navigation'

const languagePrefixes = {
  en: '',
  fr: '/fr',
}

type MultilingualLink = {
  language: Locale
  path: string
}

export function useMultilingualLinks(): {
  current: MultilingualLink
  other: MultilingualLink
} {
  const {i18n} = useTranslation()
  const location = useLocation()
  const language = i18n.language as Locale
  const path = location.pathname
  const current = {
    language,
    path,
  }

  const otherLanguage: Locale = language === 'en' ? 'fr' : 'en'
  const otherLanguagePrefix = languagePrefixes[otherLanguage]
  const cleanPathName = path.replace(/^\/fr/, '')
  const currentNavLink = navLinks.find(({to}) => {
    return to[language] === path
  })
  const otherLanguagePath = currentNavLink
    ? currentNavLink.to[otherLanguage]
    : otherLanguagePrefix + cleanPathName
  const other = {
    language: otherLanguage,
    path: otherLanguagePath,
  }
  return {current, other}
}

export default function useOtherLanguageLink() {
  const links = useMultilingualLinks()
  return links.other.path
}
