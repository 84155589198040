import 'unfetch/polyfill'
import 'url-search-params-polyfill'
import React from 'react'
import * as firebase from 'firebase/app'
import * as Sentry from '@sentry/browser'
import env from './src/lib/env'
import UserProvider from './src/providers/UserProvider'
import SWRProvider from './src/providers/SWRProvider'
import './src/i18n'
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3'

firebase.initializeApp({
  apiKey: env.GATSBY_FIREBASE_API_KEY,
  authDomain: env.GATSBY_FIREBASE_AUTH_DOMAIN,
})

const SENTRY_DSN = env.GATSBY_SENTRY_DSN
const GATSBY_GOOGLE_RECAPTCHA_KEY = env.GATSBY_GOOGLE_RECAPTCHA_KEY
const BRANCH = env.VERCEL_GITHUB_COMMIT_REF

if (SENTRY_DSN) {
  const sentryEnvironment = BRANCH === 'master' ? 'production' : 'staging'
  Sentry.init({dsn: SENTRY_DSN, environment: sentryEnvironment})
}

export const onClientEntry = () => {
  // Necessary to include the polyfill
}

export const wrapPageElement = ({
  element,
  props,
}: {
  element: React.ReactNode
  props: any
}) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={GATSBY_GOOGLE_RECAPTCHA_KEY}>
      <UserProvider {...props}>
        <SWRProvider>{element}</SWRProvider>
      </UserProvider>
    </GoogleReCaptchaProvider>
  )
}
