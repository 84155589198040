/** @jsx jsx */
import {jsx} from 'theme-ui'
import {Link} from 'gatsby'
import {
  DetailedHTMLProps,
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
} from 'react'

export type LinkOrButtonProps =
  | (DetailedHTMLProps<
      AnchorHTMLAttributes<HTMLAnchorElement>,
      HTMLAnchorElement
    > & {href: string})
  | {to: string}
  | DetailedHTMLProps<
      ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >

const LinkOrButton: React.FC<LinkOrButtonProps> = (props) => {
  if ('href' in props) {
    return <a {...props}></a>
  }
  if ('to' in props) {
    return <Link {...props} />
  }
  return <button {...props} />
}

export default LinkOrButton
