import * as firebase from 'firebase/app'
import 'firebase/auth'
import React, {useCallback, useEffect, useState} from 'react'
import {toast} from 'react-toastify'

export type UserContextValue = {
  isAuthenticated: boolean
  logout: () => Promise<void>
  loading: boolean
  token: string | null
}

export const UserContext = React.createContext<UserContextValue>({
  isAuthenticated: false,
  logout: () =>
    new Promise((resolve, reject) => reject('User context not loaded yet')),
  loading: true,
  token: null,
})

export const UserProvider: React.FC = ({children}) => {
  const [user, setUser] = useState<{
    loading: boolean
    token: string | null
  }>({
    loading: true,
    token: null,
  })
  useEffect(() => {
    return firebase.auth().onAuthStateChanged(async (_user) => {
      if (_user) {
        const token = await _user.getIdToken()
        if (user.loading || user.token !== token)
          setUser({
            loading: false,
            token,
          })
      } else {
        if (user.loading || !!user.token)
          setUser({
            loading: false,
            token: null,
          })
      }
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  const logout = useCallback(async () => {
    try {
      firebase.auth().signOut()
      toast.success('You logged out from the app')
    } catch (e) {
      toast.error("Logout didn't work properly")
    }
  }, [])
  return (
    <UserContext.Provider
      value={{
        ...user,
        isAuthenticated: !!user.token,
        logout,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider
