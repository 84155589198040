/** @jsx jsx */
import {jsx} from 'theme-ui'
import Feature from './Feature'

type Props = {
  features: Array<{
    icon: string
    numbers: string
    text: string
  }>
}

const CauseWater4AllNumbers: React.FC<Props> = ({features}) => {
  return (
    <div
      sx={{
        mt: [0, , 73],
        mx: 'auto',
        width: ['100%', , 1440 - 204 * 2 + 40],
        maxWidth: '90%',
      }}
    >
      <div
        sx={{
          display: 'flex',
          flexDirection: ['column', , 'row'],
        }}
      >
        {features.map((feature) => {
          return (
            <Feature color="primary" key={feature.text} isSmall {...feature} />
          )
        })}
      </div>
      <div
        sx={{
          bg: '#BCCCD1',
          width: [295, '80%', 600],
          mx: 'auto',
          height: 1,
          mt: 45,
          mb: 35,
        }}
      ></div>
    </div>
  )
}

export default CauseWater4AllNumbers
