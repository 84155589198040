/** @jsx jsx */
import {jsx} from 'theme-ui'
import FacebookIcon from 'images/components/socials/Facebook.svg'
// import InstagramIcon from 'images/components/socials/Instagram.svg'
import WhatsAppIcon from 'images/components/socials/WhatsApp.svg'
import {useStaticQuery, graphql} from 'gatsby'
import {ShareButtonQuery} from '../graphqlTypes'
import {useTranslation} from 'react-i18next'
const socials = [
  //   {
  //     icon: InstagramIcon,
  //   },
  {
    icon: FacebookIcon,
    hrefGenerator: (urlToShare: string) => {
      return `http://www.facebook.com/sharer.php?u=${encodeURIComponent(
        urlToShare,
      )}`
    },
  },
  {
    icon: WhatsAppIcon,
    hrefGenerator: (urlToShare: string) => {
      return `https://api.whatsapp.com/send?text=${encodeURIComponent(
        urlToShare,
      )}`
    },
    props: {
      'data-action': 'share/whatsapp/share',
    },
  },
]

type Props = {
  pagePath: string
}

const ShareButton: React.FC<Props> = ({pagePath}) => {
  const {t} = useTranslation()
  const data: ShareButtonQuery = useStaticQuery(graphql`
    query ShareButton {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)
  const siteUrl = data?.site?.siteMetadata?.siteUrl
  if (!siteUrl) {
    throw new Error('We need a siteUrl in metadata to build the site')
  }
  const currentPageUrl = `${siteUrl}${pagePath}`
  return (
    <div
      sx={{
        borderRadius: 5,
        height: 50,
        fontWeight: 600,
        fontSize: 14,
        backgroundColor: 'lightGrey',
        borderColor: 'lightGrey',
        color: 'primary',
        mt: 18,
        position: 'relative',
        '&:hover .normal': {
          opacity: 0,
        },
        '&:hover .hover': {
          opacity: 100,
        },
      }}
    >
      <div
        className="normal"
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          opacity: [0, , 1],
          transition: 'opacity, 0.2s linear',
        }}
      >
        {t('Share')}
      </div>
      <div
        className="hover"
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          opacity: [1, , 0],
          transition: 'opacity, 0.2s linear',
        }}
      >
        <span
          sx={{
            color: '#BECBCE',
            position: 'absolute',
            left: 26,
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        >
          Share
        </span>
        {socials.map(({icon: Icon, hrefGenerator, props}, index) => {
          const href = hrefGenerator(currentPageUrl)
          return (
            <a
              key={index}
              href={href}
              target="blank"
              rel="noopener noreferrer"
              sx={{
                p: 15,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              {...props}
            >
              <Icon
                sx={{
                  ml: index === 0 ? 0 : 6,
                  path: {
                    fill: 'primary',
                  },
                }}
              />
            </a>
          )
        })}
      </div>
    </div>
  )
}

export default ShareButton
